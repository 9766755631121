import { useLocation } from "@remix-run/react";
import { useAtom } from "jotai";
import { useEffect, useRef } from "react";
import { searchFiltersAtom } from "~/store/search";

export function useClearSearchFilters() {
	const location = useLocation();
	const [, setSearchFilters] = useAtom(searchFiltersAtom);
	const prevPathRef = useRef(location.pathname);

	useEffect(() => {
		const prevPath = prevPathRef.current;
		prevPathRef.current = location.pathname;

		if (prevPath.includes('search') && !location.pathname.includes('search')) {
			setSearchFilters({
				type: [],
				region: [],
				form: [],
				category: [],
				country: [],
				administrationRoute: [],
			});
		}
	}, [location, setSearchFilters]);
}